document.addEventListener("turbolinks:load", function () {
	const postsDiv = document.getElementById("similar-posts");

    if (postsDiv){
        const totalPages = postsDiv.dataset.totalPages;
        let nextPage = postsDiv.dataset.nextPage;
        let isFetching = false;
        let isFinish = false;

        function loadMorePosts() {
            if (isFinish || isFetching) return;

            const urlParams = new URLSearchParams(window.location.search);
            let slug = urlParams.get('slug');


            //let competition = urlParams.get('competition');
        

            isFetching = true;
            // 👇 Call `posts/index.js.erb` to fetch and attach next page posts
            $.ajax({
                url: window.location,
                type: "get",
                dataType: "script",
                data: `page=${nextPage}`,
                //data: `slug=${slug}&page=${nextPage}`,
                success: function (data) {
                    isFetching = false;
                    nextPage++;

                    if (nextPage > totalPages) {
                        isFinish = true;
                        window.removeEventListener("scroll", scrollListener);
                    } else {
                        postsDiv.dataset.nextPage = nextPage;
                    }
                },
            });
        }

        function scrollListener(event) {
            const customOffset = 0;
            // 👇 Trigger loadMorePosts() when the scroll position reaches the bottom. https://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom#answer-40370876
            if (
                window.innerHeight + window.scrollY + customOffset >=
                document.body.scrollHeight
            ) {
                loadMorePosts();
            }
        }

        window.addEventListener("scroll", scrollListener); /* 👈 Add scrollListener */
    }

});