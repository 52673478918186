document.addEventListener("turbolinks:load", function () {
	const videosDiv = document.getElementById("videos");


	if (videosDiv){
		const totalPages = videosDiv.dataset.totalPages;
		let nextPage = videosDiv.dataset.nextPage;
		let isFetching = false;
		let isFinish = false;

			
		function loadMoreVideos() {
			if (isFinish || isFetching) return;

			const urlParams = new URLSearchParams(window.location.search);

			let slug = urlParams.get('slug');
			
			isFetching = true;
			$.ajax({
				url: window.location,
				type: "get",
				dataType: "script",
				data: `slug=${slug}&page=${nextPage}`,
				success: function (data) {
					isFetching = false;
					nextPage++;

					if (nextPage > totalPages) {
						isFinish = true;
						window.removeEventListener("scroll", scrollListener);
					} else {
						videosDiv.dataset.nextPage = nextPage;
					}
				},
			});
		}

		function scrollListener(event) {
			const customOffset = 0;
			if (window.innerHeight + window.scrollY + customOffset >= document.body.scrollHeight) {
				loadMoreVideos();
			}
		}

		window.addEventListener("scroll", scrollListener); 

	}
});