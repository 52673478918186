$(document).on('turbolinks:load',function() {
  "use strict";











  /**
   * Testimonials slider
   */
  new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });

 


  /**
   * Information slider
   */
  new Swiper('.informations-slider', {
    loop: true,
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 12 Pouces
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 13 Pouces
        1280: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 15 Pouces
        1366: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 19 Pouces
        1400: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 27 Pouces
        2560: {
          slidesPerView: 8,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.informations-slider');
        },
        resize: function () {
          checkArrow('.informations-slider');
        }
      }
  });






   /**
   * Divertissements slider
   */
  new Swiper('.divertissements-slider', {
   
    loop: true,
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
         // 12 Pouces
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 13 Pouces
        1280: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 15 Pouces
        1366: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 19 Pouces
        1400: {
          slidesPerView: 5,
          spaceBetween: 15
        },
         // 27 Pouces
         2560: {
          slidesPerView: 6,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.divertissements-slider');
        },
        resize: function () {
          checkArrow('.divertissements-slider');
        }
      }
  });



  /**
   * Events slider
   */
  new Swiper('.events-slider', {
      /*speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },*/
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
         // 12 Pouces
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 13 Pouces
        1280: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 15 Pouces
        1366: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 19 Pouces
        1400: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 27 Pouces
        2560: {
          slidesPerView: 6,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.events-slider');
        },
        resize: function () {
          checkArrow('.events-slider');
        }
      }
  });

  


  /**
   * Campaigns slider
   */
  new Swiper('.campaigns-slider', {
      speed: 400,
      loop: true,
      /*autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },*/
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
         // 12 Pouces
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 13 Pouces
        1280: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        // 15 Pouces
        1366: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 19 Pouces
        1400: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        // 27 Pouces
        2560: {
          slidesPerView: 6,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.campaigns-slider');
        },
        resize: function () {
          checkArrow('.campaigns-slider');
        }
      }
  });



   /**
   * Posts slider
   */
  new Swiper('.posts-slider', {
    speed: 400,
    loop: true,
    /*autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },*/
    slidesPerView: 5,
    spaceBetween: 15,
    freeMode: true,
    pagination: {
      el: ".swiper-pagination",
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // Responsive breakpoints
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 15
      },
      // 12 Pouces
      1024: {
        slidesPerView: 4,
        spaceBetween: 15
      },
      // 13 Pouces
      1280: {
        slidesPerView: 4,
        spaceBetween: 15
      },
      // 15 Pouces
      1366: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      // 19 Pouces
      1400: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      // 27 Pouces
      2560: {
        slidesPerView: 6,
        spaceBetween: 15
      },
      
    },
    
    on: {
      init: function() {
        checkArrow('.posts-slider');
      },
      resize: function () {
        checkArrow('.posts-slider');
      }
    }
  });


  /**
   * Débats slider
   */
  new Swiper('.debats-slider', {
    loop: true,
      slidesPerView: 6,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
       // 12 Pouces
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 13 Pouces
        1280: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 15 Pouces
        1366: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 19 Pouces
        1400: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        // 27 Pouces
        2560: {
          slidesPerView: 8,
          spaceBetween: 15
        },
      },
      on: {
        init: function() {
          checkArrow('.debats-slider');
        },
        resize: function () {
          checkArrow('.debats-slider');
        }
      }
  });


  /**
   * Parutions slider
   */
  new Swiper('.parutions-slider2', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 5,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.parutions-slider2');
        },
        resize: function () {
          checkArrow('.parutions-slider2');
        }
      }
  });

  /* Music layouts */

  /**
   * Music albums slider
   */
  new Swiper('.music-albums-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.music-albums-slider');
        },
        resize: function () {
          checkArrow('.music-albums-slider');
        }
      }
  });

  /**
   * Singles slider
   */
  new Swiper('.singles-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


  /**
   * Music sounds slider
   */
  new Swiper('.sounds-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.sounds-slider');
        },
        resize: function () {
          checkArrow('.sounds-slider');
        }
      }
  });


  /**
   * LP slider
   */
  new Swiper('.lp-musics-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      }
  });


  /**
   * EP slider
   */
  new Swiper('.ep-musics-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.ep-musics-slider');
        },
        resize: function () {
          checkArrow('.ep-musics-slider');
        }
      }
  });



  







  /** Newspapers layouts */


  /**
   * Parutions slider
   */
  new Swiper('.parutions-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.parutions-slider');
        },
        resize: function () {
          checkArrow('.parutions-slider');
        }
      }
  });

  /**
   * Series dramatiques slider
   */
  new Swiper('.newspapers-slider', {
      slidesPerView: 3,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        }
      },
      on: {
        init: function() {
          checkArrow('.newspapers-slider');
        },
        resize: function () {
          checkArrow('.neswpapers-slider');
        }
      }
  });




 


  /**
   * Porfolio isotope and filter
   */
  /*window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

  });
  */

  /**
   * Initiate portfolio lightbox 
   */
  /*const portfolioLightbox = GLightbox({
    selector: '.portfolio-lightbox'
  });*/

  /**
   * Portfolio details slider
   */
  /*new Swiper('.portfolio-details-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });*/



  function checkArrow(section) {
 
    
    var swiperPrev = document.querySelector(`${section} .swiper-button-prev`);
    var swiperNext = document.querySelector(`${section} .swiper-button-next`);
    
    if (swiperPrev !== null && swiperNext !== null){
      if ( window.innerWidth < 1024  ) {
        //console.log('Success', window.innerWidth);
        //swiperPrev.style.display = 'block';
        //swiperNext.style.display = 'block';
        swiperPrev.style.display = 'none';
        swiperNext.style.display = 'none';
      } else {
        //swiperPrev.style.display = 'none';
        //swiperNext.style.display = 'none';
        swiperPrev.style.display = 'block';
        swiperNext.style.display = 'block';
      }
    }
  }









});