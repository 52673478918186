document.addEventListener("turbolinks:load", function () {
	const eventsDiv = document.getElementById("events");

	if (eventsDiv){
		const totalPages = eventsDiv.dataset.totalPages;
		let nextPage = eventsDiv.dataset.nextPage;
		let isFetching = false;
		let isFinish = false;

	
		function loadMoreEvents() {
			if (isFinish || isFetching) return;

			//const urlParams = new URLSearchParams(window.location.search);

			//let competition = urlParams.get('competition');
		

			isFetching = true;
			// 👇 Call `posts/index.js.erb` to fetch and attach next page posts
			$.ajax({
				url: "list",
				type: "get",
				dataType: "script",
				data: `page=${nextPage}`,
				success: function (data) {
					isFetching = false;
					nextPage++;

					if (nextPage > totalPages) {
						isFinish = true;
						window.removeEventListener("scroll", scrollListener);
					} else {
						eventsDiv.dataset.nextPage = nextPage;
					}
				},
			});
		}

		function scrollListener(event) {
			const customOffset = 0;
			// 👇 Trigger loadMorePosts() when the scroll position reaches the bottom. https://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom#answer-40370876
			if (
				window.innerHeight + window.scrollY + customOffset >=
				document.body.scrollHeight
			) {
				loadMoreEvents();
			}
		}

		window.addEventListener("scroll", scrollListener); /* 👈 Add scrollListener */

	}
});